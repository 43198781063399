import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row, Table } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const CSShadowPalaceShopPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Shadow Palace - Shop</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/shadow_shop.png"
            alt="Shadow Palace"
          />
        </div>
        <div className="page-details">
          <h1>Shadow Palace - Shop</h1>
          <h2>What to buy and what to avoid in the Shadow Palace shop.</h2>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="Shop" />
        <p>
          The Shadow's Palace shop is another source of Binaries, but it's also
          the only place where you can buy Molds that allow you to craft
          Spectral gear.
        </p>
        <p>
          Depending on which Floor you are able to clear, you will have to
          ignore some of the items as you won't have enough Shadow Fragments to
          buy everything.
        </p>
        <SectionHeader title="What to buy?" />
        <StaticImage
          src="../../../images/counterside/generic/sp_shop.jpg"
          alt="Shadow Palace Shop"
        />
        <p>
          Items with a <strong>red outline</strong> are your priority and you
          should always buy them every week/month.
        </p>
        <p>
          Items with a <strong>blue outline</strong> you can buy if you have
          leftover Shadow Fragments left.
        </p>
        <p>Items without any outline you can simply ignore.</p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <td>Items</td>
              <th>Price</th>
              <th>Limit (weekly)</th>
              <th>Limit (monthly)</th>
              <th>Price (weekly)</th>
              <th>Price (monthly)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Set Binaries</td>
              <td>20</td>
              <td>-</td>
              <td>50</td>
              <td>-</td>
              <td>1000</td>
            </tr>
            <tr>
              <td>Tunning Binaries</td>
              <td>5</td>
              <td>30</td>
              <td>120</td>
              <td>150</td>
              <td>600</td>
            </tr>
            <tr>
              <td>Ultraprecise T6 Spectral Universal Mold</td>
              <td>120</td>
              <td>1</td>
              <td>4</td>
              <td>120</td>
              <td>480</td>
            </tr>
            <tr>
              <td>T6 Spectral Universal Mold (-75%)</td>
              <td>15</td>
              <td>15</td>
              <td>60</td>
              <td>225</td>
              <td>900</td>
            </tr>
            <tr>
              <td>T6 Spectral Universal Mold (-50%)</td>
              <td>30</td>
              <td>15</td>
              <td>60</td>
              <td>450</td>
              <td>1800</td>
            </tr>
          </tbody>
        </Table>
        <p>Note: Prices are shown in Shadow Fragments currency.</p>
        <p>Priority:</p>
        <ol>
          <li>Set Binaries</li>
          <li>Tuning Binaries</li>
          <li>T6 Spectral Universal Mold (-75%)</li>
        </ol>
        <p>
          Be aware that the Set Binaries are monthly-limited, so unless you're
          able to clear Floor #3 or #4 fully, buy the weekly items first.
        </p>
        <SectionHeader title="Molds" />
        <p>
          Molds are required to craft Spectral gear and as with any gear, it can
          roll between R and SSR quality. The Ultraprecise T6 Spectral Universal
          Mold guarantees you that you will get SR or SSR roll.
        </p>
        <p>
          Still, compared to other gear, even R rarity Spectral can be used with
          success, but be aware that some sets can only roll on SR and SSR
          Spectral.
        </p>
        <p>You can learn more about the Spectral gear in this guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Spectral gear"
            link="/counter-side/guides/shadow-palace-spectral"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_spectral.png"
                alt="Shadow Palace"
              />
            }
          />
        </Row>
        <SectionHeader title="Conclusion" />
        <p>
          You need <strong>2500 Shadow Fragments</strong> per month to buy all
          red outlined items:
        </p>
        <ul>
          <li>
            Floor #1 gives 1890 Shadow Fragments per month, so you won't be even
            able to get everything - prioritize Binaries in that case,
          </li>
          <li>
            Floor #2 gives 2490 Shadow Fragments per month, so it's nearly
            enough to buy all required items,
          </li>
          <li>
            Floor #3 and above allow you start buying blue outlined items.
          </li>
        </ul>
      </div>
    </DashboardLayout>
  );
};

export default CSShadowPalaceShopPage;

export const Head: React.FC = () => (
  <Seo
    title="Shadow Palace shop guide | Counter Side | Prydwen Institute"
    description="What to buy and what to avoid in the Shadow Palace shop."
  />
);
